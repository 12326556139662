import {CLEAR_REDUX_STATE, CLEAR_REPORT_DATA, FETCHED_ANALYTICS_DATA, FETCHING_ANALYTICS_DATA_ERROR_CODE} from '../actions/action_generators/PreferablyActions';
import {getBaseGeoEventJSON, getBaseCurrentPriorPeriodJSON} from '../utilities/DashboardUtil';
import {DASHBOARD_EVENT_DETAIL, DASHBOARD_GEO_EVENT_DETAIL} from '../utilities/AppConstants';

import * as immutable from 'object-path-immutable';

let baseGeoJSON = getBaseGeoEventJSON();
let baseCurentPriorPeriodJSON = getBaseCurrentPriorPeriodJSON();
let preInitialState = {reportData: {pieChart: [], recentActivity: []}, 
    dashboardData: {
        listSubTrends: {
            currentPeriod: {
                subscribe:[],  unsubscribe: []
            },
            trends: {
            },
            priorPeriod: {
                subscribe: [], unsubscribe: []
            }
        } 
    }
};
preInitialState[DASHBOARD_EVENT_DETAIL] = baseCurentPriorPeriodJSON;
preInitialState[DASHBOARD_GEO_EVENT_DETAIL] = baseGeoJSON;
preInitialState.error = null;
const initialState = preInitialState;

function analytics(state = initialState, action) {
    switch (action.type) {
        
        case CLEAR_REDUX_STATE: 
            return initialState;          

        case CLEAR_REPORT_DATA:
            return Object.assign({}, state, {
                reportData: {pieChart: [], recentActivity: []}
            });

        case FETCHED_ANALYTICS_DATA:
            //var newState = Object.assign({}, state);
            
            //pull property to update from the action 
            var path = action.stateProperty;
            let newState = immutable.set(state, path, action.data);

            //newState[action.stateProperty] = action.data;

            return newState;

        case FETCHING_ANALYTICS_DATA_ERROR_CODE:
            return Object.assign({}, state, {
                error: action.error
            });

        default:
        return state
  }
}

export default analytics
