import {CLEAR_REDUX_STATE, CLEAR_ERROR_MSG} from '../actions/action_generators/PreferablyActions';

const initialState = {error: null, sessionExpired: false};

function applicationError(state = initialState, action) {

    var endsWithError = action.type.endsWith('_ERROR_CODE');

    if (endsWithError) { 
        let newState = {};
        let result = Object.assign(newState, state, {
            error: action.error
        });   

        return result;
    }

    switch (action.type) {
        
        case CLEAR_REDUX_STATE: 
            return initialState;  

        case CLEAR_ERROR_MSG:
            return Object.assign({}, state, {
                error: null
            });

        default:
            return state;
    }
}

export default applicationError
