import {CLEAR_REDUX_STATE, FETCHED_PREFCENTERS, SET_CURRENT_PREFCENTER, SET_CURRENT_PREF_CENTER_TAB, 
    RESET_PREF_CTR_UPDATE_ACTIVE, SET_CURRENT_SETTING_TAB, DELETED_PREF_CENTER_DEPLOYMENT, UPDATED_PREFCENTER,
    DELETED_PREF_CENTER_ACTION_COMPLETED, UPLOADED_PREFCENTER_IMAGE, UPDATED_PREF_CENTER_DEPLOYMENT,
     UPDATE_SESSION_PREF_CENTER, ADD_SELECTED_ITEM, REMOVE_SELECTED_ITEM, REMOVE_ALL_SELECTED_ITEMS, UPDATING_PREFCENTER, 
     UPDATING_PREFCENTER_ERROR_CODE, CONCURRENT_UPDATE_ERROR_WEB_EXP_ERROR_CODE, FETCHING_PREFCENTERS_ERROR_CODE, 
     UPDATING_PREF_CENTER_DEPLOYMENT_ERROR_CODE, DELETING_PREFCENTER_ERROR_CODE, SET_PREV_GREET_MSG_DISPLAY} 
    from '../actions/action_generators/PreferablyActions';
     
import {removeNonUpdatablePrefCenterProps} from '../actions/prefCenterPropsUtil';

const deepClone = require('clone-deep');

const initialState = {items: [], prefCenter: {}, 
                      sessionBaseline: null, updateActive: false, updateStart: null, 
                      imageURL: '', currentSettingTab: '', appProfile: null,
                      emailListSelectedState: {}, interestSelectedState: {}, interestOptionSelectedState: {}};

function preferenceCenters(state = initialState, action) {
    switch (action.type) {
        
        case CLEAR_REDUX_STATE: 
            return initialState;  

        case FETCHED_PREFCENTERS:
            var newState = Object.assign({}, state, {
                items: action.items,
                receivedAt: action.receivedAt
            })

            return newState;

        case SET_CURRENT_PREFCENTER:
            let test = Object.assign({}, state, {
                prefCenter: action.prefCenter,
                lastDBChange: action.prefCenter.lastChange,
                previewGreetDisplay: false,
                sessionBaseline: removeNonUpdatablePrefCenterProps(deepClone(action.prefCenter))
            });
            return test;

        case SET_CURRENT_PREF_CENTER_TAB:
            return Object.assign({}, state, {
                currentPrefCenterTab: action.tab
            });

        case RESET_PREF_CTR_UPDATE_ACTIVE: 
            return Object.assign({}, state, {
                updateActive: false
            });
        

        case SET_CURRENT_SETTING_TAB:
            return Object.assign({}, state, {
                currentSettingTab: action.settingTab
            });

        case SET_PREV_GREET_MSG_DISPLAY:
            return Object.assign({}, state, {
                previewGreetDisplay: action.display
            });

        case DELETED_PREF_CENTER_DEPLOYMENT:
        case UPDATED_PREFCENTER:
            if (!action.prefCenter) {
                return state;
            }
            var anotherState = deepClone(state);
                
            anotherState.updateActive = false;
            anotherState.updateStart = null;

            //assign to the baseline new props/updated props
            //Object.assign(anotherState.sessionBaseline, action.prefCenter);
            anotherState.sessionBaseline = removeNonUpdatablePrefCenterProps(deepClone(action.prefCenter));
            anotherState.lastDBChange = action.prefCenter.lastChange;

            //merge updated properties - not ideal, need to find a better solution
            if (action.prefCenter.updated) {
                anotherState.prefCenter.updated = action.prefCenter.updated;                
            }

            if (action.prefCenter.id && !anotherState.prefCenter.id) {
                anotherState.prefCenter = deepClone(action.prefCenter);
                //anotherState.prefCenter.id = action.prefCenter.id;
            }
/*
            if (action.prefCenter.colors && !anotherState.prefCenter.colors) {
                anotherState.prefCenter.colors = action.prefCenter.colors;
            }

            if (action.prefCenter.styles && !anotherState.prefCenter.styles) {
                anotherState.prefCenter.styles = action.prefCenter.styles;
            }         

            if (!anotherState.prefCenter.name && anotherState.sessionBaseline.name) {
                anotherState.prefCenter.name = anotherState.sessionBaseline.name;
            }

            if (!anotherState.prefCenter.profileSource && anotherState.sessionBaseline.profileSource) {
                anotherState.prefCenter.profileSource = anotherState.sessionBaseline.profileSource;
            } */
            //}

            var shouldAdd = true;
            var foundIndex;

            anotherState.items.map((val, index) => {
                if (val.id === action.prefCenter.id) {
                    shouldAdd = false;
                    foundIndex = index
                }
            })

            if (shouldAdd) {
                anotherState.items.push(action.prefCenter);
            }
            else {
                anotherState.items.splice(foundIndex, 1, action.prefCenter);
            }

            return anotherState;

        case DELETED_PREF_CENTER_ACTION_COMPLETED:
            let removePrefCenter = (state, prefCenter) => {
                return state.items.filter((val) => {
                    return val.id !== prefCenter.id;
                })
            }

            var newState = Object.assign({}, state);  
            newState.items = removePrefCenter(state, action.prefCenter);
            //newState.prefCenter = newState.items[0];

            return newState;

        case UPLOADED_PREFCENTER_IMAGE:
            return Object.assign({}, state, {
                imageURL: action.imageURL
            });


        case UPDATED_PREF_CENTER_DEPLOYMENT:
            return Object.assign({}, state, {
                deployment: action.prefCenterDeployment,
                prefCenter: action.preferenceCenter
            });

        case UPDATE_SESSION_PREF_CENTER: {
            console.log('updating session state...')
            var newState = deepClone(state);
            newState.prefCenter = action.prefCenter;
            return newState; 
        }

        case ADD_SELECTED_ITEM:
            var newState = deepClone(state);
            newState[action.selectedType + 'SelectedState'][action.id] = action.displayState;
            return newState;

        case REMOVE_SELECTED_ITEM:
            var newState = deepClone(state);
            delete newState[action.selectedType + 'SelectedState'][action.id];
            return newState;            
            
        case REMOVE_ALL_SELECTED_ITEMS:
            var newState = deepClone(state);
            if (action.selectedType) {
                newState[action.selectedType + 'SelectedState'] = {};
            } else {
                newState.emailListSelectedState = {};
                newState.interestSelectedState = {};
                newState.interestOptionSelectedState = {};
            }
            
            return newState;    

        case UPDATING_PREFCENTER:
            var updatedState = deepClone(state);
            var newState = Object.assign({}, updatedState, {updateActive: true, updateStart: Date.now()});
            return newState;

        case UPDATING_PREFCENTER_ERROR_CODE:
            var updatedState = deepClone(state);
            var newState = Object.assign({}, updatedState, {updateActive: false, updateStart: null});
            return newState; 

        case CONCURRENT_UPDATE_ERROR_WEB_EXP_ERROR_CODE:
            var updatedState = deepClone(state);
            var newCount = 0;
            var newState = Object.assign({}, updatedState, {updateActive: false});
            return newState;
    
        case FETCHING_PREFCENTERS_ERROR_CODE:
        case UPDATING_PREF_CENTER_DEPLOYMENT_ERROR_CODE:
        case DELETING_PREFCENTER_ERROR_CODE:
            var result = Object.assign({}, state, {
                error: action.error
            })    

            return result;

        default:
            return state
    }
}

export default preferenceCenters
