//constants used in the application

export const PREFERABLY = 'Preferably';

export const ROOT_ELEMENT = '#app-container';
export const OK = 'ok';
export const ERROR = 'error';
export const PRIOR_PENDING = 'prior_pending';
export const CONFIGURED = 'configured';
export const PREFERENCE_CENTER = 'preferenceCenter';
export const CLIENT = 'client';
export const TOKEN_CONTENT = 'tokenContent';
export const CONTENT_BLOCK = 'contentBlock';
export const PREFERENCE = 'preference';
export const EMAIL_ADDRESS_PARAM = 'emailAddr';

export const PREF_GREEN = '#3aab47';

export const CLIENT_DEVICE_KEY = '-pref-client-device';

export const CONFIRM = 'confirm';

export const POST = 'POST';
export const JSON_TYPE = 'json';
export const JSON_CONTENT = 'application/json';

export const PREVIEW_GREETING = 'previewGreeting';
export const EXP_SETTINGS = 'exp_settings';

export const BASIC_INLINE = 'basic_inline';
export const BASIC_INLINE_MULTI = 'basic_inline_multi';

//dashboard query state props
export const DASHBOARD_EVENT_DETAIL = 'dashboardEventDetail';
export const DASHBOARD_GEO_EVENT_DETAIL = 'dashboardGeoEventDetail';

//pref-ctr ctx
export const COLLAPSED = 'collapsed';
export const EXPANDED = 'expanded';

//environments
export const PROD = 'prod';
export const DEV = 'dev';
export const QA = 'qa';
export const STAGING = 'staging';

//page ids
export const DASHBOARD2 = 1;
export const REPORTS = 2;
export const ADMIN = 3;
export const USER_ACCOUNT = 4;
export const DEPLOYMENTS = 5;
export const PREFERENCES = 6; 

//user status values
export const AWAITING_FIRST_LOGIN = 'awaiting first login';
export const AWAITING_PWD_CHANGE = 'awaiting password change';
export const ACTIVE = 'active';
export const DISABLED = 'disabled';
export const PWD_RESET = 'password reset';
export const MFA_DEVICE_ASSOC = 'device associated';
export const MFA_DEVICE_NOT_ASSOC = 'device not associated';

//Activity types
export const SESSION_EXPIRED_ACT_TYPE = 'session-expired';
export const PASSWORD_RESET_ACT_TYPE = 'reset-password'; 
export const LOGIN_ACT_TYPE = 'login';
export const LOGOUT_ACT_TYPE = 'logout';
export const MFA_SUBMITTED_ACT_TYPE = 'user-mfa-code-submitted';
export const MFA_SETUP_ACT_TYPE = 'user-mfa-code-setup';
export const USER_CREATED_ACT_TYPE = 'user-created';
export const USER_UPDATED_ACT_TYPE = 'user-updated';
export const USER_ENABLED_ACT_TYPE = 'user-enabled';
export const USER_DISABLED_ACT_TYPE = 'user-disabled';
export const USER_DELETED_ACT_TYPE = 'user-deleted';
export const INVITE_SENT_ACT_TYPE = 'user-invite-sent';
export const INVITE_RESENT_ACT_TYPE = 'user-invite-resent';
export const USER_MFA_DEVICE_RESET_ACT_TYPE = 'user-mfa-device-reset'; 
export const FORCE_PWD_RESET = 'force-pwd-reset'; 
export const LOGIN_ERROR_ACT_TYPE = 'login-error';
export const INVALID_SESSION_ERROR_ACT_TYPE = 'invalid-session-error';
export const USER_MFA_CODE_ERROR_ACT_TYPE = 'user-mfa-code-error';
export const NEW_PASSWORD_REQUIRED_ACT_TYPE = 'new-password-required';

export const SESSION_EXPIRED = 'Session expired';

//user operation
export const RESEND_INVITE_ACT_TYPE = 'resend-invitation';
export const SEND_VERIF_CODE_ACT_TYPE = 'send-verification-code';    

//API operations
export const FETCH_SUBS = 'fetch-subscriptions';
export const UPDATE_PROF = 'update-profile';
export const VALIDATE_PERSON = 'validate-person-context';
export const READ_PROFILE = 'read';

//event types
export const PC_BOUNCE = 'pc_bounce';
export const PC_VISIT = 'pc_visit';
export const PC_CLICK = 'pc_click';
export const UNSUB = 'unsubscribe';
export const SUB = 'subscribe';
export const UNIQUE = 'is_unique';

export const PREF_CENTER_EVENTS = 'pc_events';
export const SUB_EVENTS = 'sub_events';

export const ALL_TYPE = 'p__all__p';

export const DEVICE_DSK = 'dsk';
export const DEVICE_TAB = 'tab';
export const DEVICE_MOB = 'mob';
export const DEVICE_STV = 'stv';
export const DEVICE_CAR = 'car';
export const DEVICE_BOT = 'bot';
export const DEVICE_UKN = 'ukn';
export const DEVICE = 'dev';

export const NETWORK_UNAVAILABLE = 'network-unavailable';
export const NETWORK_RETRY = 'network-retry';
export const UNKNOWN_COGNITO_ERROR = 'unknown-cognito-error';
export const GENERIC_ERROR = 'generic-error';
export const PREFERABLY_PROFILE_FIELD = 'prefProfileField';

//tab names
export const CONFIGURE = 'configure';
export const SELECT_TEMPLATE = 'select-template';
export const DESIGN = 'design';
export const DEPLOY = 'deploy';

//web experience types
export const PREF_CTR = 'prefCtr';
export const ACCT_SIGNUP = 'acctSignup';
export const INT_CAP = 'interestCap';
export const SUB_ONLY = 'subOnly';
export const UNSUB_ONLY = 'unsubOnly';

//integration partner ids
export const PARDOT = 'pardot';
export const SFMC = 'sfmc';
export const SFDC = 'sfdc';
export const MAILCHIMP = 'mailchimp';
export const SLACK = 'slack';

//integration object types
export const PROSPECT = 'prospect';
export const CONTACT = 'contact';
export const SUBSCRIBER = 'subscriber';
export const DATA_EXTENSION = 'dataExtension';
export const LEAD = 'lead';

//Subscription components
export const EMAIL_LIST_SELECT_V1 = 'EmailListSelectionV1';
export const LIST_ORDER_V1 = 'ListOrderV1';
export const ACTIONS_CONFIG = 'ActionsConfig';
export const NOTIFICATIONS_CONFIG = 'NotificationsConfig';
export const FIELDS_CONFIG = 'FieldsConfig';
export const FIELD_MAPPING = 'FieldMapping';
export const CSS_EDITOR = 'CSSEditorConfig';
export const FONT_SETTINGS = 'FontSettings';
export const COLOR_SETTINGS = 'ColorSettings';
export const ADDL_LISTS = 'AdditionalLists';
export const CTA = 'CallToAction';
export const INTERESTS_CONFIG = 'InterestsConfig';

export const MAPPING_INFO_MISSING = 'MappingInfoMissing';
export const PARTNER_RESOURCE_NOT_FOUND = 'PartnerResourceNotFound';

//dashboard
export const FLAT = 'flat';
export const UP_GOOD = 'up_good';
export const UP_BAD = 'up_bad';
export const DOWN_GOOD = 'down_good';
export const DOWN_BAD = 'down_bad';

//channels
export const EMAIL = 'email';
export const WEB = 'web';
export const SOCIAL = 'social';
export const VIDEO = 'video'

//Spinner types
export const TOP_COVER = 'top-cover';
export const MAIN_COVER = 'main-cover';
export const ADMIN_COVER = 'admin-cover';
export const TOP_INLINE = 'top-inline';
export const MAIN_INLINE = 'main-inline';
export const ADMIN_INLINE = 'admin-inline';

//slack app ids
export const SLACK_REPORTS = 'A014J565VGD';

//API errors
export const CONDITIONAL_CHECK_FAILED = 'ConditionalCheckFailedException';

//AWS Constants

export const REGION = 'us-east-1';
export const COGNITO_IDENTITY_POOL_ID = STAGE === 'prod' ? 'us-east-1:26c0da5b-f326-4a4f-98c7-19ec32b3d1cf' : 'us-east-1:80a26fa6-35fc-4333-8cc6-92610139cff4';
export const COGNITO_USER_POOL_ID = STAGE === 'prod' ? 'us-east-1_6GT8XNI5f' : 'us-east-1_qtfxWFKre'; 
export const COGNITO_CLIENT_ID = STAGE === 'prod' ? 'pfs515oa642ut2599nbsv4rus' : '17nqp0p4gu45iq5dg4u5nrmjjg'; 
export const COGNITO_PROVIDER_ID = STAGE === 'prod' ? 'cognito-idp.us-east-1.amazonaws.com/us-east-1_6GT8XNI5f' : 'cognito-idp.us-east-1.amazonaws.com/us-east-1_qtfxWFKre';
export const GOOGLE_API_KEY =  STAGE === 'prod' ? 'AIzaSyAE-LNNLsA-EHFdApsQJzRTLONdqUoZCnc' : 'AIzaSyCULKRYE4jCwaNLy9fLJwIFXwImjkHQDGc';  //need to get these from the server...


//Object.defineProperty(AppConstants, 'API_ENDPOINT', {value: 'https://v04rk4df1m.execute-api.us-east-1.amazonaws.com/', writable: false, enumerable: true, configurable: false});
const baseDomain = 'api.preferably.com';
export const API_ENDPOINT = STAGE === 'prod' ? 'https://' + baseDomain : 'https://' + STAGE + '.' + baseDomain;
export const ASSETS_DOMAIN = STAGE === 'prod' ? 'https://assets.preferably.com' :  `https://${STAGE}.assets.preferably.com`;
export const  RESOURCES_DOMAIN = STAGE === 'prod' ? 'https://resources.preferably.com' :  `https://${STAGE}.resources.preferably.com`;
export const  APP_DOMAIN = STAGE === 'prod' ? 'https://app.preferably.com' :  `https://${STAGE}.app.preferably.com`;

//font family constants
export const ARIAL = 'Arial,helvetica,sans-serif';
export const ARIAL_BLACK = 'Arial Black,Gadget,sans-serif';
export const COMIC_SANS = 'Comic Sans MS,cursive,sans-serif';
export const COURIER_NEW = 'Courier New,Courier,monospace';
export const GEORGIA = 'Georgia,serif';
export const IMPACT = 'Impact,Charcoal,sans-serif';
export const LUCIDA_CONSOLE = 'Lucida Console,Monaco,monospace';
export const LUCIDA_SANS = 'Lucida Sans Unicode,Lucida Grande,sans-serif';
export const TAHOMA = 'Tahoma,Geneva,sans-serif';
export const TIMES = 'Times New Roman,Times,serif';
export const VERDANA = 'Verdana,Geneva,sans-serif';

export const STANDARD_FONT_FAMILIES = [
    ARIAL, ARIAL_BLACK, COMIC_SANS, COURIER_NEW, GEORGIA, IMPACT, LUCIDA_CONSOLE, LUCIDA_SANS,
    TAHOMA, TIMES, VERDANA
];

export const LATO = 'Lato, sans-serif';
export const MONTSERRAT = 'Montserrat, sans-serif';
export const OPEN_SANS = 'Open Sans, sans-serif';
export const OSWALD = 'Oswald, sans-serif';
export const POPPINS = 'Poppins, sans-serif';
export const RALEWAY = 'Raleway, sans-serif';
export const ROBOTO = 'Roboto, sans-serif';
export const ROBOTO_CONDENSED = 'Roboto Condensed, sans-serif';
export const ROBOTO_MONO = 'Roboto Mono, monospace';
export const SOURCE_SANS_PRO = 'Source Sans Pro, sans-serif';

export const GOOGLE_FONT_FAMILIES = [
    LATO, MONTSERRAT, OPEN_SANS, OSWALD, POPPINS, RALEWAY, ROBOTO, ROBOTO_CONDENSED, 
    ROBOTO_MONO, SOURCE_SANS_PRO
];

export const GOOGLE_FONT_URL = 'https://fonts.googleapis.com/css?family=Lato:wght@400;500;600;700;800;900|Open+Sans:wght@400;500;600;700;800;900|Oswald:wght@400;500;600;700;800;900|Poppins:wght@400;500;600;700;800;900|Raleway:wght@400;500;600;700;800;900|Roboto:wght@400;500;600;700;800;900|Roboto+Condensed:wght@400;500;600;700;800;900|Roboto+Mono:wght@400;500;600;700;800;900|Source+Sans+Pro:wght@400;500;600;700;800;900&display=swap';