//import * as immutable from 'object-path-immutable';

import {CLEAR_REDUX_STATE, CLEAR_PREF_DEPLOY_CODE, CLEAR_PREF_CTR_CONTENT, FETCHED_TEMPLATE, FETCHED_PREVIEW, 
    FETCHED_DEPLOYMENT_CODE, FETCHED_BUILDER_PREVIEW, CLEAR_PREF_CENTER_TEMPLATE, FETCHED_CONTENT_DATA, 
    REMOVE_CONTENT_DATA, FETCHING_CONTENT_DATA_ERROR_CODE, FETCHING_PREVIEW_ERROR_CODE,
    FETCHING_S3_SIGNITURE_ERROR_CODE, FETCHING_DEPLOYMENT_CODE_ERROR_CODE, FETCHING_TEMPLATE_ERROR_CODE, 
    APPLY_DESIGN_PROPERTY, SET_DESIGN_MODAL_DISPLAYED} from '../actions/action_generators/PreferablyActions';

const deepClone = require('clone-deep');

const initialState = {deploymentCode: '', prefCenterContent: {}, prefCenterContentData: {}, 
                      templateHTML: {}, prefCenterDesignContext: {}, designModalDisplayed: false};

function templates(state = initialState, action) {
    switch (action.type) {
        
        case CLEAR_REDUX_STATE: 
            return initialState;  
        
        case CLEAR_PREF_DEPLOY_CODE: {
            var newState = deepClone(state);
            newState.deploymentCode = '';
            return newState;
        }

        case SET_DESIGN_MODAL_DISPLAYED: 
            var newState = deepClone(state);
            newState.designModalDisplayed = action.displayed;
            return newState;

        case APPLY_DESIGN_PROPERTY: 
            var newState = deepClone(state);
            if (!newState.prefCenterDesignContext[action.instanceId]) {
                newState.prefCenterDesignContext[action.instanceId] = {};
            }
            newState.prefCenterDesignContext[action.instanceId] = 
                Object.assign(newState.prefCenterDesignContext[action.instanceId], action.designObject);
            return newState;

        case CLEAR_PREF_CTR_CONTENT:
            var newState = deepClone(state);
            newState.prefCenterContent = {};
            return newState;

		case FETCHED_TEMPLATE:
            var newState = deepClone(state);
            if (action.stateProp) {
                newState.templateHTML[action.stateProp] = action.template;
    
            } else {
                newState.template = action.template;
            }

            return newState;

        case FETCHED_PREVIEW:
            var newState = deepClone(state);
            newState.preview = action.preview;
			return newState;
            
		case FETCHED_DEPLOYMENT_CODE:
            var newState = deepClone(state);
            newState.deploymentCode = action.deploymentCode;
			return newState;
            
		case FETCHED_BUILDER_PREVIEW:
            var newState = deepClone(state);
            newState.builderPreview = action.builderPreview;
            return newState;

        case CLEAR_PREF_CENTER_TEMPLATE: 
            var newState = deepClone(state);
            newState.builderTemplate = null;
            return newState;


        case FETCHED_CONTENT_DATA:
            var newState = deepClone(state);
            newState.prefCenterContentData[action.instanceId] = action.contextData;
            return newState;
        

            //var newState = {prefCenterContent: {}};
            //var path = 'prefCenterContentData.' + action.instanceId;
            //var newState = immutable.set(state, path, action.contextData);
            //return newState;

        case REMOVE_CONTENT_DATA:
            var newState = deepClone(state);
            delete newState.prefCenterContentData[action.id];
            return newState;


        case FETCHING_CONTENT_DATA_ERROR_CODE:
        case FETCHING_PREVIEW_ERROR_CODE:
        case FETCHING_S3_SIGNITURE_ERROR_CODE:
        case FETCHING_DEPLOYMENT_CODE_ERROR_CODE:
        case FETCHING_TEMPLATE_ERROR_CODE:
            var newState = deepClone(state);
            newState.error = action.error;
            return newState;           

        default:
            return state;
	}
}
export default templates
