// All Actions used in Action Generators need to be UNIQUE
// Note that some actions are matched on 'ending' in reducers like _ERROR_CODE and _ACTION_COMPLETED
// by NotificationManager and ExceptionManager
// If you want to perform multiple changes on state objects - 'listen'/match on action name in multiple reducers
// an action type or action is delivered to all reducers regardless of the type of action

export const UPDATE_LOADING_OPTS = 'UPDATE_LOADING_OPTS';

//Client
export const FETCHED_CLIENT = 'FETCHED_CLIENT';
export const FETCHED_ADMIN_CLIENT_RECORD = 'FETCHED_ADMIN_CLIENT_RECORD';
export const CLEAR_ADMIN_CLIENT_RECORD = 'CLEAR_ADMIN_CLIENT_RECORD';
export const CLEAR_IP_FIELDS = 'CLEAR_IP_FIELDS';
export const CLEAR_IP_OBJECTS = 'CLEAR_IP_OBJECTS';

export const UPDATED_CLIENT_ACTION_COMPLETED = 'UPDATED_CLIENT_ACTION_COMPLETED';
export const UPDATED_CONNECTOR_ACTION_COMPLETED = 'UPDATED_CONNECTOR_ACTION_COMPLETED';
export const TESTED_CONNECTOR_ACTION_COMPLETED = 'TESTED_CONNECTOR_ACTION_COMPLETED';
export const UPLOADED_TEMPLATE = 'UPLOADED_TEMPLATE';
export const FETCHED_INT_OBJECTS= 'FETCHED_INT_OBJECTS'; 
export const FETCHED_INT_FIELDS = 'FETCHED_INT_FIELDS';

export const FETCHING_INT_FIELDS_ERROR_CODE = 'FETCHING_INT_FIELDS_ERROR_CODE';
export const TEST_CONNECTOR_ERROR_CODE = 'TEST_CONNECTOR_ERROR_CODE';
export const TEST_CONNECTOR_MC_ERROR_CODE = 'TEST_CONNECTOR_MC_ERROR_CODE';
export const UPDATE_CONNECTOR_ERROR_CODE = 'UPDATE_CONNECTOR_ERROR_CODE';
export const FETCHING_CLIENT_ERROR_CODE = 'FETCHING_CLIENT_ERROR_CODE';
export const PERFORM_PARTNER_OP_ERROR_CODE = 'PERFORM_PARTNER_OP_ERROR_CODE';

export const UPDATING_CLIENT_ERROR_CODE = 'UPDATING_CLIENT_ERROR_CODE';
export const UPLOAD_TEMPLATE_ERROR_CODE = 'UPLOAD_TEMPLATE_ERROR_CODE';
export const FETCHING_INT_OBJECTS_ERROR_CODE = 'FETCHING_INT_OBJECTS_ERROR_CODE';    

export const FETCHED_INT_PARTNER_LISTS = 'FETCHED_INT_PARTNER_LISTS';
export const FETCHING_INT_PARTNER_LISTS_ERROR_CODE = 'FETCHING_INT_PARTNER_LISTS_ERROR_CODE';
export const FETCHING_MAPPED_PARTNER_LISTS_ERROR_CODE = 'FETCHING_MAPPED_PARTNER_LISTS_ERROR_CODE';
export const FETCH_COUNTRY_LIST_ERROR_CODE = 'FETCH_COUNTRY_LIST_ERROR_CODE';
export const UPLOADING_IMAGE_ERROR_CODE = 'UPLOADING_IMAGE_ERROR_CODE';

export const FETCHED_I18N_MESSAGES = 'FETCHED_I18N_MESSAGES';
export const FETCHING_I18N_MESSAGES_ERROR_CODE = 'FETCHING_I18N_MESSAGES_ERROR_CODE';

export const MAPPED_LISTS = 'MAPPED_LISTS';

//User
export const CLEAR_PWD_CHANGE_FLG = 'CLEAR_PWD_CHANGE_FLG';
export const PASSWORD_RESET_ACTION_COMPLETED = 'PASSWORD_RESET_ACTION_COMPLETED';
export const PASSWORD_RESET_ERROR_CODE = 'PASSWORD_RESET_ERROR_CODE';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_REDUX_STATE = 'CLEAR_REDUX_STATE';
export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT'
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS'
export const VERIFICATION_CODE_SENT = 'VERIFICATION_CODE_SENT';
export const CHECKED_SESSION = 'CHECKED_SESSION';
export const FETCHED_USER = 'FETCHED_USER';
export const FETCHED_USERS = 'FETCHED_USERS';
export const UPDATED_USER = 'UPDATED_USER_ACTION_COMPLETED';
export const ADDED_USER = 'ADDED_USER_ACTION_COMPLETED';
export const NEW_USER = 'NEW_USER';  //do not add action_completed, not handled by notification manager
export const NEW_USER_PWD_SET = 'NEW_USER_PWD_SET';
export const FETCHED_ACTIVITY = 'FETCHED_ACTIVITY';
export const CLEAR_ACTIVITY = 'CLEAR_ACTIVITY';
export const LOGGING_OUT = 'LOGGING_OUT';
export const CODE_FOR_TOTP = 'CODE_FOR_TOTP';
export const TOTP_VERIFIED = 'TOTP_VERIFIED';
export const SETUP_TOTP_REQUIRED = 'SETUP_TOTP_REQUIRED';
export const TOTP_REQUIRED = 'TOTP_REQUIRED';
export const RESET_MFA_ERROR_CODE = 'RESET_MFA_ERROR_CODE';
export const CLEAR_TOTP_STATE = 'CLEAR_TOTP_STATE';

export const NO_USER_INFO_AVAILABLE = 'NO_USER_INFO_AVAILABLE';  //not handled by exception manager
export const LOGGED_IN_ERROR_CODE = 'LOGGED_IN_ERROR';  //do not add _CODE, not handled by exception manager
export const PASSWORD_CHANGE_ERROR_CODE = 'PASSWORD_CHANGE_ERROR_CODE';
export const SEND_VERIFICATION_CODE_ERROR_CODE = 'SEND_VERIFICATION_CODE_ERROR_CODE';
export const FETCHING_ACTIVITY_ERROR = 'FETCHING_ACTIVITY_ERROR_CODE';
export const ADDING_USER_ERROR_CODE = 'ADDING_USER_ERROR_CODE';
export const UPLOADING_PROFILE_IMAGE_ERROR_CODE = 'UPLOADING_PROFILE_IMAGE_ERROR_CODE';
export const UPDATING_USER_ERROR_CODE = 'UPDATING_USER_ERROR_CODE';
export const DUPLICATE_USER_ERROR_CODE = 'DUPLICATE_USER_ERROR_CODE';
export const FETCHED_USERS_ERROR_CODE = 'FETCHED_USERS_ERROR_CODE';
export const FETCHED_USER_ERROR_CODE = 'FETCHED_USER_ERROR_CODE';
export const FETCHING_SMS_COUNTRIES_ERROR_CODE = 'FETCHING_SMS_COUNTRIES_ERROR_CODE';
export const FETCHED_ACTIVITY_ERROR = 'FETCHED_ACTIVITY_ERROR';

export const DISABLE_USER_ERROR_CODE = 'DISABLE_USER_ERROR_CODE';
export const ADMIN_FETCH_USER_ERROR_CODE = 'ADMIN_FETCH_USER_ERROR_CODE';
export const CHECKING_SESSION_ERROR_CODE = 'CHECKING_SESSION_ERROR_CODE';
export const ADMIN_UPDATE_USER_ERROR_CODE = 'ADMIN_UPDATE_USER_ERROR_CODE';
export const ENABLE_USER_ERROR_CODE = 'ENABLE_USER_ERROR_CODE';
export const DELETE_USER_ERROR_CODE = 'DELETE_USER_ERROR_CODE';
export const RESEND_INVITE_ERROR_CODE = 'RESEND_INVITE_ERROR_CODE';
export const RESEND_VERIFICATION_CODE_ACTION_COMPLETED = 'RESEND_VERIFICATION_CODE_ACTION_COMPLETED';
export const RESET_MFA_ACTION_COMPLETED = 'RESET_MFA_ACTION_COMPLETED';

export const USER_ENABLED = 'USER_ENABLED_ACTION_COMPLETED';
export const USER_DISABLED = 'USER_DISABLED_ACTION_COMPLETED';
export const USER_DELETED = 'USER_DELETED_ACTION_COMPLETED';

//Preference
export const CONCURRENT_UPDATE_ERROR_EMAIL_EXP_ERROR_CODE = 'CONCURRENT_UPDATE_ERROR_EMAIL_EXP_ERROR_CODE';
export const UPDATE_PREF_AUTOSAVE_ENABLED = 'UPDATE_PREF_AUTOSAVE_ENABLED';
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';
export const SET_CURRENT_PREF = 'SET_CURRENT_PREF';
export const SET_PREF_IMAGE = 'SET_PREF_IMAGE';
export const UPDATE_SESSION_PREF = 'UPDATE_SESSION_PREF';
export const SET_PROPERTY_PANEL_SETTINGS = 'SET_PROPERTY_PANEL_SETTINGS';
export const FETCHED_PREFS = 'FETCHED_PREFS';
export const FETCHED_DEFAULT_PREF = 'FETCHED_DEFAULT_PREF';
export const UPDATED_PREF = 'UPDATED_PREF';
export const DELETED_PREF_ACTION_COMPLETED = 'DELETED_PREF_ACTION_COMPLETED';
export const DEPLOYED_PREF = 'DEPLOYED_PREF';
export const TEMPLATE_CHANGED = 'TEMPLATE_CHANGED';
export const CLEAR_PREF_DEPLOY_CODE = 'CLEAR_PREF_DEPLOY_CODE';

export const UPDATING_PREF = 'UPDATING_PREF';
export const DELETING_PREF_ERROR_CODE = 'DELETING_PREF_ERROR_CODE';
export const CLEAR_PREF_DEPLOYMENTS = 'CLEAR_PREF_DEPLOYMENTS';
export const CLEAR_PREF_DEPLOYMENT = 'CLEAR_PREF_DEPLOYMENT';

export const FETCHING_PREF_ERROR_CODE = 'FETCHING_PREF_ERROR_CODE';
export const DELETING_PREF_DEPLOYMENT_ERROR_CODE = 'DELETING_PREF_DEPLOYMENT_ERROR_CODE';
export const UPDATING_PREF_ERROR_CODE = 'UPDATING_PREF_ERROR_CODE';
export const DELETING_PREF_PRIOR_ACTIVITY_ERROR_CODE = 'DELETING_PREF_PRIOR_ACTIVITY_ERROR_CODE';
export const DEPLOYING_PREF_ERROR_CODE = 'DEPLOYING_PREF_ERROR_CODE';
export const DELETING_PREF_CENTER_DEPLOYMENT_ERROR_CODE = 'DELETING_PREF_CENTER_DEPLOYMENT_ERROR_CODE';    
export const FETCHING_PREFS_ERROR_CODE = 'FETCHING_PREFS_ERROR_CODE';
export const FETCHING_DEFAULT_PREF_ERROR_CODE = 'FETCHING_DEFAULT_PREF_ERROR_CODE';    
export const SETTING_PREF_IMAGE_ERROR_CODE = 'SETTING_PREF_IMAGE_ERROR_CODE';
export const UPLOADING_PREF_IMAGE_ERROR_CODE = 'UPLOADING_PREF_IMAGE_ERROR_CODE';

//Preference Center
export const CONCURRENT_UPDATE_ERROR_WEB_EXP_ERROR_CODE = 'CONCURRENT_UPDATE_ERROR_WEB_EXP_ERROR_CODE';
export const CLEAR_PREF_CTR_CONTENT = 'CLEAR_PREF_CTR_CONTENT';
export const UPDATE_SESSION_PREF_CENTER = 'UPDATE_SESSION_PREF_CENTER';
export const SET_CURRENT_SETTING_TAB = 'SET_CURRENT_SETTING_TAB';
export const SET_CURRENT_PREFCENTER = 'SET_CURRENT_PREFCENTER';
export const SET_CURRENT_PREF_CENTER_TAB = 'SET_CURRENT_PREF_CENTER_TAB';
export const FETCHED_PREFCENTERS = 'FETCHED_PREFCENTERS';
export const UPDATED_PREFCENTER = 'UPDATED_PREFCENTER';
export const DELETED_PREF_CENTER_ACTION_COMPLETED = 'DELETED_PREF_CENTER_ACTION_COMPLETED';    
export const UPLOADED_PREFCENTER_IMAGE = 'UPLOADED_PREFCENTER_IMAGE';
export const UPDATED_PREF_CENTER_DEPLOYMENT = 'UPDATED_PREF_CENTER_DEPLOYMENT';
export const DELETED_PREF_CENTER_DEPLOYMENT = 'DELETED_PREF_CENTER_DEPLOYMENT';
export const DELETED_PREF_DEPLOYMENT = 'DELETED_PREF_DEPLOYMENT';
export const UPDATING_PREFCENTER = 'UPDATING_PREFCENTER';
export const FETCHING_PREFCENTERS_ERROR_CODE = 'FETCHING_PREFCENTERS_ERROR_CODE';
export const UPDATING_PREFCENTER_ERROR_CODE = 'UPDATING_PREFCENTER_ERROR_CODE';
export const DELETING_PREFCENTER_ERROR_CODE = 'DELETING_PREFCENTER_ERROR_CODE';
export const UPLOADING_PREFCENTER_LOGO_IMAGE_ERROR_CODE = 'UPLOADING_PREFCENTER_LOGO_IMAGE_ERROR_CODE';
export const UPDATING_PREF_CENTER_DEPLOYMENT_ERROR_CODE = 'UPDATING_PREF_CENTER_DEPLOYMENT_ERROR_CODE';
export const CLEAR_PREF_CTR_DEPLOYMENTS = 'CLEAR_PREF_CTR_DEPLOYMENTS';
export const CLEAR_PREF_CTR_DEPLOYMENT = 'CLEAR_PREF_CTR_DEPLOYMENT';
export const SET_PREV_GREET_MSG_DISPLAY = 'SET_PREV_GREET_MSG_DISPLAY';

export const RESET_PREF_UPDATE_ACTIVE = 'RESET_PREF_UPDATE_ACTIVE';
export const CLONING_PREF_CENTER_ERROR_CODE = 'CLONING_PREF_CENTER_ERROR_CODE';
export const REMOVE_CONTENT_DATA = 'REMOVE_CONTENT_DATA';

export const REMOVE_ALL_SELECTED_ITEMS = 'REMOVE_ALL_SELECTED_ITEMS';
export const REMOVE_SELECTED_ITEM = 'REMOVE_SELECTED_ITEM';
export const ADD_SELECTED_ITEM = 'ADD_SELECTED_ITEM';

export const RESET_PREF_CTR_UPDATE_ACTIVE = 'RESET_PREF_CTR_UPDATE_ACTIVE';
export const FETCHED_CONTENT_DATA = 'FETCHED_CONTENT_DATA';
export const FETCHING_CONTENT_DATA_ERROR_CODE = 'FETCHING_CONTENT_DATA_ERROR_CODE';
export const PLACEHOLDER_CHECK_ERROR_CODE = 'PLACEHOLDER_CHECK_ERROR_CODE';
export const RENDER_STRING_ERROR_CODE = 'RENDER_STRING_ERROR';

//Content
export const FETCHED_CONTENT = 'FETCHED_CONTENT';
export const FETCHED_CONTENT_COMPONENTS = 'FETCHED_CONTENT_COMPONENTS';
export const FETCHING_CONTENT_ERROR_CODE = 'FETCHING_CONTENT_ERROR_CODE';
export const FETCHING_CONTENT_COMPONENTS_ERROR_CODE = 'FETCHING_CONTENT_COMPONENTS_ERROR_CODE';    

//Template
export const CLEAR_PREF_CENTER_TEMPLATE = 'CLEAR_PREF_CENTER_TEMPLATE'
export const FETCHED_TEMPLATE = 'FETCHED_TEMPLATE';
export const FETCHED_PREVIEW = 'FETCHED_PREVIEW';
export const FETCHED_BUILDER_PREVIEW = 'FETCHED_BUILDER_PREVIEW';
export const FETCHED_DEPLOYMENT_CODE = 'FETCHED_DEPLOYMENT_CODE';

export const FETCHING_TEMPLATE_ERROR_CODE = 'FETCHING_TEMPLATE_ERROR_CODE';
export const FETCHING_S3_SIGNITURE_ERROR_CODE = 'FETCHING_S3_SIGNITURE_ERROR_CODE';
export const FETCHING_PREVIEW_ERROR_CODE = 'FETCHING_PREVIEW_ERROR_CODE';
export const FETCHING_DEPLOYMENT_CODE_ERROR_CODE = 'FETCHING_DEPLOYMENT_CODE_ERROR_CODE';

export const APPLY_DESIGN_PROPERTY = 'APPLY_DESIGN_PROPERTY';
export const SET_DESIGN_MODAL_DISPLAYED = 'SET_DESIGN_MODAL_DISPLAYED';

//Pref Center Deployment
export const FETCHING_PREF_CENTER_DEPLOYMENT_ERROR_CODE = 'FETCHING_PREF_CENTER_DEPLOYMENT_ERROR_CODE';
export const FETCHED_PREF_CENTER_DEPLOYMENT = 'FETCHED_PREF_CENTER_DEPLOYMENT';
export const FETCHED_PREF_CENTER_DEPLOYMENTS = 'FETCHED_PREF_CENTER_DEPLOYMENTS';
export const FETCHING_PREF_CENTER_DEPLOYMENTS_ERROR_CODE = 'FETCHING_PREF_CENTER_DEPLOYMENTS_ERROR_CODE';

//Preference Deployment
export const FETCHING_PREFERENCE_DEPLOYMENT_ERROR_CODE = 'FETCHING_PREFERENCE_DEPLOYMENT_ERROR_CODE';
export const FETCHED_PREFERENCE_DEPLOYMENT = 'FETCHED_PREFERENCE_DEPLOYMENT';
export const FETCHED_PREFERENCE_DEPLOYMENTS = 'FETCHED_PREFERENCE_DEPLOYMENTS';
export const FETCHING_PREFERENCE_DEPLOYMENTS_ERROR_CODE = 'FETCHING_PREFERENCE_DEPLOYMENTS_ERROR_CODE';

//Analytics
export const FETCHED_ANALYTICS_DATA =  'FETCHED_ANALYTICS_DATA';
export const FETCHING_ANALYTICS_DATA_ERROR_CODE = 'FETCHING_ANALYTICS_DATA_ERROR_CODE';
export const CLEAR_REPORT_DATA = 'CLEAR_REPORT_DATA';

//Application Error
export const CLEAR_ERROR_MSG = 'CLEAR_ERROR_MSG';

//Application Notification
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

export const SET_PAGE_CONTEXT = 'SET_PAGE_CONTEXT';
export const CLEAR_PAGE_TITLE = 'CLEAR_PAGE_TITLE';
export const CLEAR_PAGE_CONTEXT = 'CLEAR_PAGE_CONTEXT';
export const VALIDATE_INPUT_ERROR_CODE = 'VALIDATE_INPUT_ERROR_CODE';

//slack
export const POST_MSG_ACTION_COMPLETED = 'POST_MSG_ACTION_COMPLETED';
export const POST_MSG_ERROR_CODE = 'POST_MSG_ERROR_CODE';
export const RPT_UPLOAD_ERROR_CODE = 'RPT_UPLOAD_ERROR_CODE';
