import {CLEAR_REDUX_STATE, SET_PAGE_CONTEXT, UPDATE_LOADING_OPTS, VALIDATE_INPUT_ERROR_CODE} from '../actions/action_generators/PreferablyActions';
const initialState = {pageContext: {title: '', id: -1}, loadingOpts: null};

function app(state = initialState, action) {

    switch (action.type) {
        case CLEAR_REDUX_STATE: 
            return initialState;  

        case SET_PAGE_CONTEXT:
            var newState = Object.assign({}, state);
            newState.pageContext = action.pageContext;
            return newState;        
        
        case UPDATE_LOADING_OPTS:
            var newState = Object.assign({}, state);
            newState.loadingOpts = action.options;
            newState.now = Date.now();
            return newState;
            
        case VALIDATE_INPUT_ERROR_CODE:
            let result = Object.assign({}, state, {
                error: action.error
            });

            return result;

        default:
            return state;
    }
}

export default app