import {CLEAR_REDUX_STATE, NO_USER_INFO_AVAILABLE, FETCHED_USER, FETCHED_USERS, UPDATED_USER, ADDED_USER, 
     FETCHED_ACTIVITY, USER_DISABLED, FETCHED_USER_ERROR_CODE,
     FETCHED_USERS_ERROR_CODE, UPLOADING_PROFILE_IMAGE_ERROR_CODE, UPDATING_USER_ERROR_CODE, 
     ADDING_USER_ERROR_CODE, FETCHED_ACTIVITY_ERROR, DISABLE_USER_ERROR_CODE,
     ENABLE_USER_ERROR_CODE, DELETE_USER_ERROR_CODE, RESEND_INVITE_ERROR_CODE, ADMIN_FETCH_USER_ERROR_CODE, 
     ADMIN_UPDATE_USER_ERROR_CODE, FETCHING_SMS_COUNTRIES_ERROR_CODE, CLEAR_ACTIVITY} from '../actions/action_generators/PreferablyActions';

const initialState = {error: null, activity: null, activityRetrieved: null, user: {}, users: null, adminUser: {}};

function user (state = initialState, action) {
    switch (action.type) {

        case CLEAR_REDUX_STATE: 
            return initialState;  

        case CLEAR_ACTIVITY: 
            return Object.assign({}, state, {
                activity: null
            });

        case NO_USER_INFO_AVAILABLE:
            return Object.assign({}, state, {
                error: action.error
            });

        case FETCHED_USER:
            return Object.assign({}, state, {
                user: action.user
            }); 

        case FETCHED_USERS:
            return Object.assign({}, state, {
                users: action.users,
            });

        case UPDATED_USER:
            var anotherState = {
                ...state,
                user: action.user
            };
            return anotherState;

        case ADDED_USER:
            return state;

        case FETCHED_ACTIVITY:
            return Object.assign({}, state, {
                activity: action.activity,
                activityRetrieved: Date.now()
            });


        case USER_DISABLED:
            return state;

        case FETCHED_USER_ERROR_CODE:
        case FETCHED_USERS_ERROR_CODE:
        case UPLOADING_PROFILE_IMAGE_ERROR_CODE:
        case UPDATING_USER_ERROR_CODE:
        case ADDING_USER_ERROR_CODE:
        case FETCHED_ACTIVITY_ERROR:
        case DISABLE_USER_ERROR_CODE:
        case ENABLE_USER_ERROR_CODE:
        case DELETE_USER_ERROR_CODE:
        case RESEND_INVITE_ERROR_CODE:
        case ADMIN_FETCH_USER_ERROR_CODE:
        case ADMIN_UPDATE_USER_ERROR_CODE:
        case FETCHING_SMS_COUNTRIES_ERROR_CODE:

            return Object.assign({}, state, {
                error: action.error
            });     

        default:
            return state;
  }
}

export default user