//import * as immutable from 'object-path-immutable';
import {CLEAR_REDUX_STATE, FETCHED_CONTENT, FETCHED_CONTENT_COMPONENTS, FETCHING_CONTENT_ERROR_CODE, 
    FETCHING_CONTENT_COMPONENTS_ERROR_CODE} from '../actions/action_generators/PreferablyActions';
    
const initialState = {contentComponent: {}, contentComponents: [], keyedContentComponents: {}};

function content(state = initialState, action, ) {
    switch (action.type) {
        
        case CLEAR_REDUX_STATE: 
            return initialState; 

        case FETCHED_CONTENT:
            return {
                ...state,
                contentComponent: action.content
            }

        case FETCHED_CONTENT_COMPONENTS:
            let keyed = {};
            if (action.contentComponents && action.contentComponents.length) {
                let contentComp = null;
                for (let ii = 0; ii < action.contentComponents.length; ii++) {
                    contentComp = action.contentComponents[ii];
                    keyed[contentComp.id] = contentComp;
                }
            }

            return {
                ...state,
                keyedContentComponents: keyed,
                contentComponents: action.contentComponents
            }            
    
        case FETCHING_CONTENT_ERROR_CODE:        
        case FETCHING_CONTENT_COMPONENTS_ERROR_CODE:
            return Object.assign({}, state, {
                error: action.error
            })

        default:
			return state;
	}
}
export default content
